<template>
  <AtomsGlobalDialog :is-open="dialog?.isOpen" :is-message-container="dialog?.isMessageContainer" @close="close" class="w-[700px] desktop:w-[950px]">

    <!-- heading -->
    <strong class="block mb-8 pb-8 uppercase font-bold text-2xl border-b border-neutral-200 text-error-500 tablet:px-10">{{ $t('Stránka v daném jazyce neexistuje') }}</strong>

    <div class="desktop:px-10">{{ $t('Ve vámi vybraném jazyce stránka neexistuje. Můžete tedy zůstat zde, nebo přejít na hlavní stránku cílového jazyka. Jakou možnost si vyberete?') }}</div>

    <!-- buttons -->
    <div class="flex flex-wrap gap-4 justify-between mt-8 pt-8 border-t border-neutral-200 desktop:px-10">
      <MoleculesButtonsButton :arrow-left="true" :ghost="true" class="order-1 w-full tablet:order-0 tablet:w-auto" @click="close">{{ $t('Zůstanu tady') }}</MoleculesButtonsButton>
      <MoleculesButtonsButton :href="dialog?.data.homePageUrl" class="order-0 w-full tablet:order-1 tablet:w-auto" :force-reload="true">{{ $t('Přejdu na hlavní stránku') }}</MoleculesButtonsButton>
    </div>
  </AtomsGlobalDialog>
</template>
<script setup>

const dialog = computed(() => useDialog().get('noAlternativeUrl'));

const close = () => useDialog().close();

</script>